import React from "react"
import { Link } from "gatsby"
import scrollTo from 'gatsby-plugin-smoothscroll'
import TestimonialSection from "../components/testimonials-section"
import Layout from "../components/layout"
import Cta from "../components/cta"
import Seo from "../components/seo"
import ArrowRight from "../components/svg/arrowRight"

const VuePage = () => (
    <Layout>
        <Seo title="Vue developers" 
            description="Vue developers in Sydney and Canberra. We develop web applications using the Vue.js Javascript library."
            pathname="/vue-developer/"
        />

        <section role="main" className="bg-purple white">
          <div className="container">
              <div className="row flex flex-jc">
                  <div className="hero-content sub-hero">
                      <h1
                      className="small-h"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="300"
                      data-sal-duration="400"
                      >Vue Developers Sydney and Canberra</h1>
                      <h2 className="hero-lead"
                      data-sal="slide-up" 
                      data-sal-easing="ease"
                      data-sal-delay="400"
                      data-sal-duration="400"
                      >
                          We offer Vue.js or React.js development services in Australia
                      </h2>
                      <div className="btn-row">
                      <button onClick={() => scrollTo('#anchor')} className="btn btn-lg btn-pink">
                          <span className="flex flex-ac down">
                          <ArrowRight />
                          </span>
                      </button>
                      </div>
                  </div>
                </div>
            </div>
        </section>

        <section className="section-pad" id="anchor">
            <div className="container">
                <div className="row">
                    <div className="cols">
                        <div className="col-26">
                            <h3>Experienced local Javascript developers</h3>
                        </div>

                        <div className="col-67 long-text">
                            <p>
                                Launch Lab offers web and application development services to startups and established companies in Australia.
                            </p>
                            <p>
                                We jumped on the <Link to="/react-developers-sydney-canberra/">React</Link> band wagon right from the beginning but as of 2019 also offer Vue.js development as a service.
                            </p>
                            <p>
                                We've developed multiple <Link to="/startup-web-application-development/">startup web applications</Link> using Vue and have developed other online solutions, like web-based chatbots, using it.
                            </p>
                            <p>
                                We also offer related <Link to="/services/">web development services</Link> including web design, backend development, chatbot development, devops and AWS hosting &amp; infrastructure management.
                            </p>
                            <p>
                                If you are looking for a Vue developer in Australia, all of our work in done onshore by experienced Javascript developers.
                            </p>
                          <div className="btn-row">
                              <Link to="/contact/" className="btn btn-lg btn-purple">
                                  <span className="flex flex-ac">
                                      Contact us
                                  <ArrowRight />
                                  </span>
                              </Link>
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <TestimonialSection />

        <Cta 
            title="Vue development quotes"
            text="Get a VueJS quote and / or a web development quote from a local onshore Australian web development team"
        />
    </Layout>
)

export default VuePage